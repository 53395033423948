import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SimpleModalService } from 'ngx-simple-modal';
import { environment } from '../../../../environments/environment';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { UtilsService } from '../../../core/services/utils.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ConfirmModalComponent } from '../../../core/component/confirm-modal/confirm-modal.component';
import { CartQuickCheckoutService } from '../../../cart/services/cart-quick-checkout.service';
import { ShoppingCart } from '../checkout/model/cart.model';
import { ProductService } from '../../../product/services/product.service';
import { BundleModalComponent } from '../../../cart/component/bundle-modal/bundle-modal.component';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';

@Component({
  selector: 'hos-cart-details',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.scss', '../../../payment-ets/component/ets-payment-box/ets-payment-box.component.scss'],
})
export class CartDetailsComponent implements OnInit, OnDestroy {
  productServiceBaseUrl: String = environment.Image_API_Endpoint + environment.Product_service_API_Endpoint;

  zipcode: any;

  @Input() showLoader: boolean;

  @Input() tempId = 'desktop';

  @Input() productsByBillingType: any;

  @Input() customerGuid: any;

  @Input() showPaymentPage: Boolean = false;

  @Output() frequencyChange = new EventEmitter<string>();

  billingType: any = 'offbill';

  billingfrequencyArr = ['Monthly', 'Annual'];

  public cartProducts: Array<any>;

  public cart: Observable<ShoppingCart>;

  cartSubscription: any;

  cartLocationID: String;

  public itemCount: number;

  grossTotal: number;

  cartData: any;

  addOnProducts: any = [];

  displayOtherProduct: any;

  customer_service_number: any;

  providerState: string;

  constructor(
    private utilsService: UtilsService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    public globalVariables: GlobalVariables,
    private simpleModalService: SimpleModalService,
    private cartQuickCheckoutService: CartQuickCheckoutService,
    private productService: ProductService,
  ) {
    const cartDataFromLocal = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
    this.zipcode = cartDataFromLocal && cartDataFromLocal.items.length > 0 ? cartDataFromLocal.items[0].zipcode : '';
    const microsite = this.sessionStorageService.getItem('providerState');
    this.providerState = microsite === 'lasvegas' ? microsite : "";
  }

  ngOnInit() {
    this.customer_service_number = environment.customer_service_number;
    this.cartProducts = [];
    this.addtoCart();
    this.globalVariables.cartSwap.subscribe(() => {
      const cartDataFromLocal = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
      this.zipcode = cartDataFromLocal && cartDataFromLocal.items.length > 0 ? cartDataFromLocal.items[0].zipcode : '';
      this.addtoCart();
    });
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  removeProduct(product) {
    this.cartQuickCheckoutService.removeItem(product, () => {
      const checkoutCartData: any = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
      this.globalVariables.cartCheckout = checkoutCartData;
      this.globalVariables.cartAmount.emit(checkoutCartData);
      if (this.productsByBillingType) {
        if (this.productsByBillingType.offbill.products && this.productsByBillingType.offbill.products.length > 0) {
          this.productsByBillingType.offbill.products.forEach((offbillData, index) => {
            if (offbillData.details.programId === product.details.programId) {
              this.productsByBillingType.offbill.products.splice(index, 1);
            }
          });
        }
        if (this.productsByBillingType.onbill.products && this.productsByBillingType.onbill.products.length > 0) {
          this.productsByBillingType.onbill.products.forEach((onbillData, index) => {
            if (onbillData.details.programId === product.details.programId) {
              this.productsByBillingType.onbill.products.splice(index, 1);
            }
          });
        }
        this.calculateBybillingType(this.productsByBillingType);
        this.resetOtherCartService();
      }
    });
  }

  setPaymentFrequency(frequency: string) {
    this.globalVariables.billingFrequencyCheckout = frequency;
    this.globalVariables.fequencyChangeEvent.emit(frequency);
    this.frequencyChange.emit(frequency);
    this.cartQuickCheckoutService.calculateCart(this.cartData);
  }

  renewProduct(product?: any) {
    this.simpleModalService
      .addModal(
        ConfirmModalComponent,
        {
          title: '',
          message: '',
          data: product,
          description:
            "By selecting 'Do Not Auto Renew',your program will not automatically renew at the conclusion of the program term and you will be required to contact American Water Resources to re-activate your protection for another 12 months to prevent a lapse in coverage",
          billingFrequency: this.globalVariables.billingFrequencyCheckout,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(() => {
        product.isAutoRenewStatus = 'Auto Renews Annually';
        if (!product.isAutoRenew) {
          product.isAutoRenewStatus = 'Doesn`t Auto Renew';
        }
      });
  }

  ngOnDestroy() {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  /**
   * Calculate total, subtoal & tax amount.
   * @param products
   */
  calculateBybillingType(products: any) {
    if (this.customerGuid) {
      products.onbill.subtotal = products.onbill.products.reduce((previous, current) => previous + current.taxData.TaxDetails.EnrollmentPrice, 0);
      products.onbill.tax = products.onbill.products.reduce((previous, current) => previous + current.taxData.TaxDetails.Tax, 0);
      products.offbill.subtotal = products.offbill.products.reduce((previous, current) => previous + current.taxData.TaxDetails.EnrollmentPrice, 0);
      products.offbill.tax = products.offbill.products.reduce((previous, current) => previous + current.taxData.TaxDetails.Tax, 0);
      products.onbill.total = (products.onbill.subtotal + products.onbill.tax).toFixed(2);
      products.offbill.total = (products.offbill.subtotal + products.offbill.tax).toFixed(2);
      products.grossTotal = Number(products.onbill.total) + Number(products.offbill.total);
    } else {
      products.onbill.subtotal = products.onbill.products.reduce((previous, current) => {
        const productAmount = current.details.monthlyPrice;
        return previous + parseFloat(productAmount);
      }, 0);
      products.onbill.tax = products.onbill.products.reduce(() => 0, 0);
      products.offbill.subtotal = products.offbill.products.reduce((previous, current) => {
        const productAmount =
          this.globalVariables.billingFrequencyCheckout === 'Monthly'
            ? current.details.monthlyPrice
            : this.globalVariables.billingFrequencyCheckout === 'Annual'
            ? current.details.annualPrice
            : current.details.annualPrice;
        return previous + parseFloat(productAmount);
      }, 0);
      products.offbill.tax = products.offbill.products.reduce(() => 0, 0);
      products.onbill.total = (products.onbill.subtotal + products.onbill.tax).toFixed(2);
      products.offbill.total = (products.offbill.subtotal + products.offbill.tax).toFixed(2);
      products.grossTotal = Number(products.onbill.total) + Number(products.offbill.total);
    }
  }

  getOtherProducts() {
    const locationCodeId = this.cartProducts[0].locationId ? this.cartProducts[0].locationId : this.sessionStorageService.getItem('location_code');
    const cartProgramId = [];
    this.displayOtherProduct = [];
    this.cartProducts.forEach(el => {
      cartProgramId.push({
        programId: el.details.programId.replace('unlimited-', ''),
        coverageType: el.details.coverageType,
      });
    });
    this.productService.getSuggestedPrograms(locationCodeId, cartProgramId).subscribe(
      data => {
        this.displayOtherProduct = data.body;
      },
      error => {
        console.log(error);
      },
    );
  }

  resetOtherCartService() {
    this.getOtherProducts();
  }

  addtoCart() {
    this.cart = this.cartQuickCheckoutService.get();
    this.cartSubscription = this.cart.subscribe(cart => {
      cart.items.forEach(() => {});
      this.cartData = cart;
      this.cartProducts = cart.items;
      this.globalVariables.cartAmount.emit(cart);
      if (this.cartProducts.length > 0 && this.addOnProducts.length <= 0) {
        this.getOtherProducts();
      } else {
        this.resetOtherCartService();
      }
      this.globalVariables.cartAmount.emit(cart);
      if (this.cartProducts.length) {
        this.cartLocationID = this.cartProducts[0].locationId;
      }
      this.itemCount = this.cartProducts.length;
    });
  }

  addCheckoutItemToCart(productData: any) {
    productData.isBundle = productData.program.products.length > 1;
    productData.status = 'PENDING_PAYMENT';
    productData.provider = 'AWR';
    productData.locationId = productData.defaultLocationCodeId;
    productData.zipcode = this.zipcode;
    const checkoutCartData: any = this.sessionStorageService.getItem('cartCheckout')
      ? JSON.parse(this.sessionStorageService.getItem('cartCheckout'))
      : [];
    const locationCode = this.sessionStorageService.getItem('location_code')
      ? this.sessionStorageService.getItem('location_code')
      : productData.locationId;
    if (
      (this.zipcode &&
        checkoutCartData &&
        checkoutCartData.items &&
        checkoutCartData.items.length > 0 &&
        this.zipcode !== checkoutCartData.items[0].zipcode) ||
      (locationCode &&
        checkoutCartData &&
        checkoutCartData.items &&
        checkoutCartData.items.length > 0 &&
        locationCode !== checkoutCartData.items[0].locationId)
    ) {
      checkoutCartData.items = [];
      this.sessionStorageService.setItem('cartCheckout', JSON.stringify(checkoutCartData));
      this.globalVariables.cartCheckout = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
    }
    this.cartQuickCheckoutService.addItem(productData, (err, data, status) => {
      if (err) {
        if (data.status === 409) {
          this.showErrorModal(
            'Upgrade to Bundle',
            `If you are looking to bundle your existing services,
          please visit your My Services page and view the list of opportunities from your current services.`,
            'Ok',
          );
        } else if (data.status === 400) {
          this.showErrorModal(
            'Upgrade to Bundle',
            `If you are looking to bundle your existing services,
          please visit your My Services page and view the list of opportunities from your current services.`,
            'Ok',
          );
        } else {
          this.showErrorModal('Error', 'Error while adding item to cart');
        }
      } else {
        if (status === 201) {
          // this.openBundle(data);
          this.resetOtherCartService();
        } else if (!!data && data.length > 0) {
          this.sessionStorageService.setItemToloclaStorage('deletedCartDataCheckout', JSON.stringify(data));
        }
        this.globalVariables.triggerOtherRelatedProduct.emit(true);
      }
    });
  }

  openBundle(data: any) {
    this.simpleModalService
      .addModal(
        BundleModalComponent,
        {
          data,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  showErrorModal(title: string, message: string, confirmationMsg?: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }
}
