import { Injectable, ViewChild } from '@angular/core';
import { find } from 'lodash-es';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { TitleCasePipe } from '@angular/common';
import { Subject, forkJoin } from 'rxjs';
import { NgForm } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { appConfig } from '../../core/appConfig/config';
import { CrmProxyService } from '../../core/services/crm-proxy.service';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { User } from '../models/user';
import { UserService } from './user.service';
import { GooglePlacesService } from '../../core/services/google-places.service';
import { SiteContentService } from '../../core/services/siteContents';
import { ProductService } from '../../product/services/product.service';
import { GlobalVariables } from '../../core/services/global-variables.service';
import { ProviderModalComponent } from '../../product/component/provider-modal/provider-modal.component';
import { CCPAUpdatesService } from '../../core/services/ccpa-updates.service';
import { AlertConfirmModalComponent } from '../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { AddressValidationModalComponent } from '../component/address-validation-modal/address-validation-modal.component';
import { CustomerServiceValidationModalComponent } from '../component/customer-service-validation-modal/customer-service-validation-modal.component';
import { ZipcodeService } from '../../core/services/zipcode.service';
import { ErrorModalComponent } from '../../core/component/error-modal/error-modal.component';
import { LocationService } from '../../home/services/location.service';
import { ZipcodeComponent } from '../../core/component/zipcode/zipcode.component';

declare let FullStory;

@Injectable()
export class GuestFormService {
  user: User = new User();

  existingUser: User = new User();

  emailExistedData: any;

  suggestedAddress: any;

  address = {
    zipcode: '',
  };

  stateCode: any;

  states: any;

  selectedAddress: any = {
    rawData: '',
    zipcode: '',
  };

  zipcode: string;

  locationCodeData: any;

  abandonedLocationCode: any;

  partnerName: any;

  homeOwner = {
    isHomeOwner: null,
    allowRental: null,
    offbillOnly: null,
  };

  selectedCity: string;

  locationCode: string;

  addressLookup: any = {
    zipcode: '',
  };

  customerDetails: any;

  existingCustomerData: any = {};

  searchApiCall;

  existingUserLocationCodeId;

  userName: string;

  oldZipcode: string;

  lastNameMismatchFound;

  confirmEmailId: string;

  formEmail: string;

  isClicked = false;

  isExistingUser = false;

  isDisposableEmail = false;

  showDropDown = false;

  zipcodeValid = false;

  showLoader = false;

  validAddress = true;

  providerAvailable = false;

  providerSelected = false;

  showProvider = false;

  backPress = false;

  customerExistInHOS = false;

  invalidCustomerNumber = false;

  isClickedCustomerNumberForm = false;

  isExistingCustReg = false;

  registerUsingCustomerNumber = false;

  invalidForm = false;

  phoneNumberExistingErrorMsg: string;

  homePhoneErrorMsg: string;

  streetNumberValidation: string = appConfig.streetNumber;

  referer: string;

  addressPresentIn = '';

  blockUnicodeRegex: string = environment.blockUnicodeRegex;

  email_regex = environment.email_regex;

  phone_number_regex = environment.phone_number_regex;

  customer_number_regex = environment.customer_number_regex;

  customer_service_number = environment.customer_service_number;

  registerFormSubmit = new Subject<any>();

  customerNumberFormSubmit = new Subject<any>();

  passNewRegisterCustomerResponse: any;

  guestToken: any = '';

  userDataInput: any;

  customerGuid: any;

  showEnrollmentCards = new Subject<any>();

  customerDbData: any;

  chance: any;

  @ViewChild(ZipcodeComponent) zipcodeComponent: ZipcodeComponent;

  constructor(
    public userService: UserService,
    public crmProxyService: CrmProxyService,
    public sessionStorageService: SessionStorageService,
    public googlePlacesService: GooglePlacesService,
    public siteContentService: SiteContentService,
    public productService: ProductService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public globalVariables: GlobalVariables,
    public zipcodeService: ZipcodeService,
    public simpleModalService: SimpleModalService,
    public ccpaUpdatesService: CCPAUpdatesService,
    public titlecasePipe: TitleCasePipe,
    public locationService: LocationService,
  ) {
    this.chance = new Chance();
  }

  initilize() {
    this.referer = this.activatedRoute.snapshot.queryParams.referer;
    this.siteContentService.statesdata$().subscribe(states => {
      this.states = states;
      if (!this.states) {
        this.states = this.sessionStorageService.getItem('statesList') ? JSON.parse(this.sessionStorageService.getItem('statesList')) : states;
      }
    });
  }

  onCustomerNumberChange() {
    this.customerExistInHOS = false;
  }

  onZipcodeUpdated(data: any) {
    if (data) {
      this.user.address[0].serviceAddress.zipcodeId = data.zipcodeId;
      this.user.address[0].serviceAddress.locationCodeId = data.locationCodeId;
      this.user.address[0].serviceAddress.zipcode = this.address.zipcode;
      this.zipcodeValid = true;
      this.stateCode = data.stateCode;
      let oofLocationCode = '';
      if (this.user && this.user.address && this.user.address.length > 0 && this.user.address[0].serviceAddress) {
        this.states = ['null', 'undefined', undefined, null, ''].includes(this.states)
          ? this.sessionStorageService.getItem('statesList')
            ? JSON.parse(this.sessionStorageService.getItem('statesList'))
            : this.states
          : this.states;
        this.user.address[0].serviceAddress.state = find(this.states, {
          key: data.stateCode,
        }).value;
        oofLocationCode = `${this.user.address[0].serviceAddress.state}150`;
      }
      if (this.sessionStorageService.getItem('providerSelected') !== '1') {
        this.showLoader = true;
        this.googlePlacesService.getCityAndStateData(this.user.address[0].serviceAddress.zipcode).subscribe(
          resultData => {
            const result = resultData.body;
            this.autoCompleteSearchCallback(result.results[0]);
            this.productService.getProviderContent(data.locationCodeId).subscribe(dataForLoc => {
              let dataForLocation = dataForLoc.body;
              this.checkLocationChangedForReferer(dataForLocation.locationCode);
              if (oofLocationCode !== dataForLocation.locationCode && this.homeOwner.offbillOnly) {
                this.productService.getLocationCode(oofLocationCode).subscribe(dataForOofLoc => {
                  const dataForOofLocationCode = dataForOofLoc.body;
                  dataForLocation = dataForOofLocationCode[0];
                  this.user.address[0].serviceAddress.locationCodeId = dataForOofLocationCode[0].locationCodeId;
                  this.checkLocationChangedForReferer(dataForLocation.locationCode);
                  this.updateDataForLocation(dataForLocation);
                });
              } else {
                this.updateDataForLocation(dataForLocation);
              }
            });
          },
          () => {
            // error in google service
            this.showLoader = false;
          },
        );
      }
    } else {
      this.zipcodeValid = false;
      this.sessionStorageService.deleteItem('providerSelected');
      this.sessionStorageService.deleteItem('isProvider');
      this.sessionStorageService.deleteItem('websitePartnerName');
    }
  }

  formKeyPressHandler(event: any) {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      event.preventDefault();
      return false;
    }
  }

  keyPressHandler(input: any, type?: any) {
    input.isBlur = false;
    if (type === 'phoneNumber') {
      this.user.phoneNumber = input.control.value;
    } else if (type === 'email') {
      this.isExistingUser = false;
      this.user.email = input.control.value;
    }
  }

  emailBlurHandler(input: any) {
    this.isDisposableEmail = false;
    input.isBlur = true;
    this.isExistingUser = false;
    if (input.valid) {
      if (environment.disposable_email.includes(input.value.split('@')[1].toLowerCase())) {
        input.isBlur = false;
        this.isDisposableEmail = true;
      } else {
        this.userService.verifyUser(input.value).subscribe(
          data => {
            if (data.status === 200) {
              this.isExistingUser = true;
              this.emailExistedData = data.body;
              input.isBlur = false;
            }
          },
          () => {
            this.isExistingUser = false;
          },
        );
      }
    }
    if (typeof FullStory === 'function') {
      FullStory.identify(`Checkout ${input.value}`, {
        displayName: `Checkout ${input.value}`,
        name: `${this.user.firstName} ${this.user.lastName}`,
        email: input.value,
      });
    }
  }

  phoneBlurHandler(input: any) {
    input.isBlur = true;
    const pattern = /^\d{10}$/;
    if (input.value && !pattern.test(input.value)) {
      if (input.name === 'phoneNumber') {
        this.homePhoneErrorMsg = 'Please enter 10 digit number';
      }
      return false;
    }
    if (input.name === 'phoneNumber') {
      this.homePhoneErrorMsg = '';
    }

    return true;
  }

  blurHandler(input: any) {
    input.isBlur = true;
    this.phoneNumberExistingErrorMsg = '';
    const pattern = /^\d{10}$/;
    if (input.value && !pattern.test(input.value)) {
      this.phoneNumberExistingErrorMsg = 'Please enter 10 digit number';
      return false;
    }
    return true;
  }

  getSuggestion(event: any) {
    this.crmProxyService.getAddressSuggestion(event.target.value).subscribe(
      res => {
        this.suggestedAddress = res.body;
        this.showDropDown = true;
      },
      () => {},
    );
  }

  updateDataForLocation(dataForLocation) {
    this.showLoader = false;
    this.locationCodeData = dataForLocation;
    this.abandonedLocationCode = this.locationCodeData.locationCode;
    this.sessionStorageService.setItem('websitePartnerName', dataForLocation.websitePartnerName);
    this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
    this.providerAvailable = false;
    /* HWR-1445 Don't show provider prompt if entered zipcode matches with zipcode stored from provider check and providerSelected is true */
    const providerSelectionDetails = JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'));
    if (dataForLocation.websitePartnerName !== '' && this.sessionStorageService.getItem('providerSelected') !== '1' && !providerSelectionDetails) {
      this.openModalPopup(this.user.address[0].serviceAddress.zipcode);
    } else if (providerSelectionDetails && providerSelectionDetails.zipCode !== this.user.address[0].serviceAddress.zipcode) {
      this.sessionStorageService.setItem('providerSelectionDetails', '{}');
    }

    if (providerSelectionDetails && providerSelectionDetails.providerSelected && providerSelectionDetails.providerValue === 'no') {
      this.partnerName = '';
      this.sessionStorageService.setItem('websitePartnerName', '');
      this.providerSelected = false;
      this.globalVariables.OOFSelected.isOOFLocationRegister = 'true';
      this.notMyProvider();
    }
  }

  autoCompleteSearchCallback(data: any) {
    if (data) {
      if (data) {
        const components = data.address_components;
        for (let i = 0; i < components.length; i++) {
          for (let j = 0; j < components[i].types.length; j++) {
            if (components[i].types[j] === 'postal_code') {
              this.selectedAddress.rawData = data;
              this.selectedAddress.zipcode = this.user.address[0].serviceAddress.zipcode;
              this.validAddress = false;
              this.sessionStorageService.setItem('selectedAddress', JSON.stringify(this.selectedAddress));
            }
          }
        }
      }
    } else {
      this.sessionStorageService.deleteItem('location_code');
      this.sessionStorageService.deleteItem('selectedAddress');
    }
  }

  checkLocationChangedForReferer(data) {
    if (this.referer) {
      /* Logic to change referer if location code is changed */
      let urlOperation: any = this.referer.split('/');
      let micrositeNames = this.sessionStorageService.getItem('micrositeName');
      micrositeNames = micrositeNames ? JSON.parse(micrositeNames) : environment.micrositeNames;

      urlOperation = urlOperation.filter(item => !micrositeNames.includes(item) && item !== '');
      if (urlOperation.length > 0 && ['products', 'product'].includes(urlOperation[0])) {
        let changed = false;
        if (data && data.toLowerCase() !== urlOperation[2]) {
          changed = true;
          urlOperation[2] = data.toLowerCase();
          this.referer = urlOperation.join('/');
        }
        let userDetail;
        if (this.existingUser.accountnumber) {
          userDetail = this.existingUser;
        } else {
          userDetail = this.user;
        }
        if (userDetail.address[0].serviceAddress && userDetail.address[0].serviceAddress.zipcode !== urlOperation[1]) {
          changed = true;
          urlOperation[1] = userDetail.address[0].serviceAddress.zipcode;
          this.referer = urlOperation.join('/');
        }
        if (changed) {
          const redirectUrlOp: any = this.referer;
          const urlTree = this.router.createUrlTree([], {
            queryParams: {
              referer: decodeURIComponent(redirectUrlOp),
            },
            queryParamsHandling: 'merge',
            preserveFragment: true,
          });
          this.router.navigateByUrl(urlTree);
        }
      }
    }
  }

  notMyProvider() {
    const savedAddress = this.sessionStorageService.getItem('selectedAddress');
    this.selectedCity = '';
    if (savedAddress) {
      const address = JSON.parse(savedAddress);
      if (address.rawData && address.rawData.address_components.length) {
        this.selectedCity = address.rawData.address_components[1].short_name;
      }
    }
    this.productService.getLocationData(this.selectedCity, this.locationCodeData.serviceState).subscribe(
      data => {
        data = data.body;
        if (data.length > 0) {
          this.productService.getProviderContent(data[0].locationCodeId).subscribe(() => {
            this.user.address[0].serviceAddress.locationCodeId = data[0].locationCodeId;
            this.checkLocationChangedForReferer(data[0].locationCode);
            this.sessionStorageService.setItem('location_code', data[0].locationCodeId);
            this.productService.setCategories();

            this.locationCode = data[0].locationCodeId;
            this.abandonedLocationCode = data[0].locationCode;
          });
        } else {
          this.getLocationDataState();
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  getLocationDataState() {
    this.selectedCity = '';
    this.productService.getLocationData(this.selectedCity, this.locationCodeData.serviceState).subscribe(
      res => {
        const dataReceived = res.body;
        if (dataReceived.length > 0) {
          this.productService.getProviderContent(dataReceived[0].locationCodeId).subscribe(() => {
            this.user.address[0].serviceAddress.locationCodeId = dataReceived[0].locationCodeId;
            this.checkLocationChangedForReferer(dataReceived[0].locationCode);
            this.sessionStorageService.setItem('location_code', dataReceived[0].locationCodeId);
            this.productService.setCategories();
            this.locationCode = dataReceived[0].locationCodeId;
            this.abandonedLocationCode = dataReceived[0].locationCode;
          });
        } else {
          this.getLocationCodeCrm(this.locationCodeData.locationCode);
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  getLocationCodeCrm(locationCode: any) {
    this.productService.getLocationDataCRM(locationCode).subscribe(
      location => {
        location = location.body;
        this.productService.getProviderContent(location.LocationDetails[0].Id).subscribe(() => {
          this.user.address[0].serviceAddress.locationCodeId = location.LocationDetails[0].Id;
          this.checkLocationChangedForReferer(location.LocationDetails[0].Code);
          this.sessionStorageService.setItem('location_code', location.LocationDetails[0].Id);
          this.globalVariables.OOFSelected.isOOFLocationRegister = 'true';
          this.locationCode = location.LocationDetails[0].Id;
          this.productService.setCategories();
          this.abandonedLocationCode = location.LocationDetails[0].Code;
        });
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  openModalPopup(selectedZipcode: String) {
    this.providerAvailable = true;
    this.simpleModalService
      .addModal(
        ProviderModalComponent,
        {
          zipcode: selectedZipcode,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        this.sessionStorageService.setItem('providerSelected', '1');
        this.sessionStorageService.setItem('isProvider', '1');
        if (isConfirmed) {
          this.showProvider = true;
          this.providerSelected = true;
          this.productService.setCategories();
          this.globalVariables.OOFSelected.isOOFLocationRegister = 'false';
        } else if (this.backPress) {
          this.backPress = false;
        } else {
          this.partnerName = '';
          this.sessionStorageService.setItem('websitePartnerName', '');
          this.providerSelected = false;
          this.globalVariables.OOFSelected.isOOFLocationRegister = 'true';
          this.notMyProvider();
        }
        if (this.user.address[0].serviceAddress.state.toLowerCase() === 'ca') {
          this.ccpaUpdatesService.getCCPAUpdates();
        }
      });
  }

  selectValue(address1, city, form) {
    if (form === 1) {
      this.user.address[0].serviceAddress.streetNumber = address1;
      this.user.address[0].serviceAddress.city = city;
    } else {
      this.addressLookup.streetNumber = address1;
      this.addressLookup.city = city;
    }
    this.showDropDown = false;
  }

  stateChangeHandler(event: any) {
    if (this.stateCode && this.stateCode !== event.key) {
      this.showAlertModal({
        title: 'Error',
        // tslint:disable-next-line:max-line-length
        message: `Zipcode does not exist in selected state, Please select ${
          find(this.states, {
            key: this.stateCode,
          }).value
        } to proceed.`,
        alertIcon: 'fa fa-exclamation-circle red',
        cancelText: 'Cancel',
        confirmText: 'Ok',
      });
    }
  }

  showAlertModal(data?: any, done?) {
    this.showLoader = false;
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: data.title,
          message: data.message,
          alertIcon: data.alertIcon,
          navigate: data.navigate,
          cancelText: data.cancelText,
          confirmText: data.confirmText,
          confirmBtnId: data.confirmBtnId ? data.confirmBtnId : 'close',
          reloadPage: data.reloadPage,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          if (done) {
            done(true);
          }
        } else if (isConfirmed === false) {
          if (done) {
            done(false);
          }
        } else if (isConfirmed === undefined) {
          /** Save Checkout User Form Data */
          this.user.address[0].serviceAddress.zipcode =
            this.address.zipcode !== '' ? this.address.zipcode : JSON.parse(this.sessionStorageService.getItem('selectedAddress')).zipcode;
          this.sessionStorageService.setItem('user_data', JSON.stringify(this.user));
          if (done) {
            done(false);
          }
        }
        if (data.navigate) {
          this.router.navigate(['']);
        }
        if (isConfirmed === 'confirmed' && data.confirmText === 'YES, CANCEL REGISTRATION') {
          this.router.navigate(['']);
        }
        if (data.reloadPage) {
          window.location.reload();
        }
      });
  }

  accountNumberKeyPressHandler(form) {
    if (form.valid) {
      let apiCall: number;
      this.showLoader = true;
      this.invalidForm = false;
      this.invalidCustomerNumber = false;
      this.isClickedCustomerNumberForm = false;
      // sending state name in query
      const query = form.value.accountNumber ? 'skip_schema_validation=yes' : '';
      if (!form.value.streetNameExisting) {
        form.value.streetNameExisting = '';
      }
      // let searchApiCall: any = '';
      if (form.value.accountNumber) {
        this.existingCustomerData = {};
        this.addressPresentIn = '';
        this.userService.searchExistingCustomerByCustomerNumber(form.value.accountNumber.trim()).subscribe(data => {
          data = data.body;
          apiCall = 1;
          this.customerDbData = data.data;
          if (data.customerExist) {
            this.addressPresentIn = 'customersDB';
            this.user.email = data.data.email;
          }

          this.searchApiCall = this.userService.searchCustomerById(form.value.accountNumber.trim(), query);
          this.selectNameModal(apiCall, this.searchApiCall, true);
          this.showLoader = false;
        });
      }
    } else {
      this.showLoader = false;
    }
  }

  getSmartAddress(strNo, strtName, state, city, zipcode, done: any) {
    this.crmProxyService.getSmartAddress(strNo, strtName, zipcode, city, state).subscribe(
      res => {
        const data = res.body;
        if (data.length && data[0].components) {
          const addressLine1 = data[0]?.delivery_line_1;
          const addressLine2 = data[0]?.delivery_line_2 ? `${data[0].delivery_line_2}, ` : '';
          const smartAddress: string = `${addressLine1},${addressLine2}${data[0]?.components?.city_name},`;
          const userAddress: string = `${strNo.trim()},${strtName ? `${strtName.trim()}, ` : ''}${city.trim()},`;
          if (
            !(
              smartAddress === userAddress &&
              zipcode === data[0].components.zipcode &&
              city.trim() === data[0].components.city_name &&
              state === data[0].components.state_abbreviation
            )
          ) {
            this.simpleModalService
              .addModal(
                AddressValidationModalComponent,
                {
                  inputAddress: {
                    streetNumber: strNo.trim(),
                    streetName: strtName.trim(),
                    city: city.trim(),
                    state: state.trim(),
                    zipcode: zipcode.trim(),
                  },
                  suggestedAddress: data[0],
                },
                {
                  wrapperClass: 'modal-main-class in',
                  closeOnClickOutside: false,
                },
              )
              .subscribe(isConfirmed => {
                done(isConfirmed);
              });
          } else {
            done();
          }
        } else {
          const GAErrorId = 'ga_track_customer_address_validation_fail';
          const alertData = {
            title: '',
            message: `Please check your address, our system indicates that this is not a valid service address.<br></br>
            If you are continuing to experience issues, please call  1-855-800-5195 to speak to a customer service representative.`,
            alertIcon: 'fa fa-info-circle red',
            cancelText: ' ',
            confirmText: 'Continue',
            confirmBtnId: GAErrorId || 'close',
          };
          this.showAlertModal(alertData);
        }
      },
      () => {
        done(undefined);
      },
    );
  }

  selectNameModal(apiCall, apiResponse, existingCustReg) {
    apiResponse.subscribe(
      data => {
        const customerData = data.body;
        this.generateName(apiCall, customerData[0], existingCustReg);
        this.showLoader = false;
      },
      error => {
        let errorMassgae = '';
        let errorName = '';
        if (apiCall === 2) {
          const apiError = error;
          errorName = apiError.error.name;
        } else {
          errorName = '';
        }
        switch (errorName) {
          case 'NameNotFoundError':
            errorMassgae = `Account holder name does not match.
          Please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span>`;
            break;
          case 'MultipleCustomersFoundError':
            errorMassgae = `We're sorry, but we are having difficulty creating your account as our records indicate there are multiple
               accounts in your name. Please contact Customer Service at ${this.customer_service_number} to complete your registration.`;
            break;
          case 'AddressNotFoundError':
          case 'InvalidAddressError':
            errorMassgae = `Property address could not be verified.
          Please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span>`;
            break;
          default:
            errorMassgae = `Account holder name or Property address could not be verified.
          Please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span>`;
        }
        this.showAlertModal({
          title: "We're sorry, we are having difficulty verifying your account information.",
          message: errorMassgae,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Ok',
          reloadPage: true,
        });
        this.showLoader = false;
      },
    );
  }

  generateName(apiCall, customerData, existingCustReg) {
    const fakeName = `${this.chance.first()} ${this.chance.last()}`;
    const originalName = this.titlecasePipe.transform(`${customerData.First_Name} ${customerData.Last_Name}`);
    const title = 'Security is important to us. For your protection please verify the name on the account';
    const data = [
      {
        name: fakeName,
      },
      {
        name: originalName,
      },
      {
        name: this.titlecasePipe.transform(`${fakeName.substr(0, fakeName.indexOf(' '))} ${customerData.Last_Name}`),
      },
      {
        name: this.titlecasePipe.transform(`${customerData.First_Name} ${fakeName.substr(fakeName.indexOf(' ') + 1)}`),
      },
    ];
    this.showCustomerServiceValidationModal(this.shuffle(data), originalName, apiCall, 1, title, existingCustReg);
  }

  shuffle(array) {
    let currentIndex = array.length;
    let temporaryValue = {};
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  showCustomerServiceValidationModal(data: any, existingCodes: any, apiCall = 0, modalFor = 0, title = '', existingCustReg = false) {
    if (!title) {
      title = 'In order to better protect your account please select the program you are currently enrolled in:';
    }
    this.simpleModalService
      .addModal(
        CustomerServiceValidationModalComponent,
        {
          title,
          message: 'In progress',
          alertIcon: 'fa fa-info-circle red',
          cancelText: 'Cancel',
          confirmText: 'Continue',
          data,
          modalFor,
          existing: existingCodes,
        } as any,
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed && !modalFor) {
          this.isExistingCustReg = existingCustReg;
          if (this.addressPresentIn !== 'customersDB') {
            this.addressPresentIn = 'customercrmsDB';
          }
          let presentIn = this.addressPresentIn;
          if (presentIn !== 'customersDB') {
            presentIn = 'customercrmsDB';
          }
          this.isExistingCustReg = existingCustReg;
          this.addressPresentIn = 'customercrmsDB';

          this.setExistingCustomerData(this.existingCustomerData).then(() => {
            this.addressPresentIn = presentIn;
            this.customerNumberFormSubmit.next({
              data: this.existingUser,
              type: this.addressPresentIn,
              emailExistedData: this.emailExistedData,
            });
          });
        } else if (!isConfirmed && isConfirmed !== undefined && !modalFor) {
          this.showAlertModal({
            title: "We're sorry, the enrollment you selected does not match with the information on your account.",
            message: `Please contact Customer Service at <span class="app-txt">
                ${this.customer_service_number}</span> to confirm your enrollment to complete registration.`,
            alertIcon: 'fa fa-exclamation-circle red',
            cancelText: ' ',
            confirmText: 'Ok',
            reloadPage: true,
          });
        } else if (isConfirmed && modalFor) {
          this.callSearchApi(apiCall, this.searchApiCall, existingCustReg);
        } else if (!isConfirmed && isConfirmed !== undefined && modalFor) {
          this.showAlertModal({
            title: "We're sorry, the name you selected does not match with the information on your account.",
            message: `Please contact Customer Service at <span class="app-txt">
                ${this.customer_service_number}</span> to confirm account holder information to complete registration.`,
            alertIcon: 'fa fa-exclamation-circle red',
            cancelText: ' ',
            confirmText: 'Ok',
            reloadPage: true,
          });
        }
      });
  }

  showEmailPopup() {
    return this.simpleModalService.addModal(
      CustomerServiceValidationModalComponent,
      {
        title: 'Enter Email Id: ',
        message: 'In progress',
        alertIcon: 'fa fa-info-circle red',
        cancelText: 'Cancel',
        confirmText: 'Continue',
        data: undefined,
        modalFor: 3,
        existing: undefined,
      } as any,
      {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: false,
      },
    );
  }

  callSearchApi(apiCall, searchApiCall, existingCustReg) {
    searchApiCall.subscribe(data => {
      const customerData = data.body;
      this.existingCustomerData = customerData[0];
      let presentIn = this.addressPresentIn;
      if (presentIn !== 'customersDB') {
        presentIn = 'customercrmsDB';
      }
      this.isExistingCustReg = existingCustReg;
      this.addressPresentIn = 'customercrmsDB';

      this.setExistingCustomerData(this.existingCustomerData).then(() => {
        this.addressPresentIn = presentIn;
        this.existingUser.customerId = this.customerDbData ? this.customerDbData.customerId : '';
        this.customerNumberFormSubmit.next({
          data: this.existingUser,
          type: this.addressPresentIn,
          emailExistedData: this.emailExistedData,
        });
      });
      this.showLoader = false;
    });
  }

  checkExistingUser(form, done) {
    this.showLoader = true;
    this.lastNameMismatchFound = '';
    const stateValue = this.user.address[0].serviceAddress.state;
    this.states = ['null', 'undefined', undefined, null, ''].includes(this.states)
      ? this.sessionStorageService.getItem('statesList')
        ? JSON.parse(this.sessionStorageService.getItem('statesList'))
        : this.states
      : this.states;
    const state = find(this.states, o => {
      if (o.value === stateValue) {
        return o;
      }
    });
    const params = `street=${encodeURIComponent(this.user.address[0].serviceAddress.streetName.trim() || '')}&street_number=${encodeURIComponent(
      this.user.address[0].serviceAddress.streetNumber.trim(),
    )}&city=${encodeURIComponent(this.user.address[0].serviceAddress.city.trim())}&state=${encodeURIComponent(
      state.value,
    )}&zip_code=${encodeURIComponent(this.user.address[0].serviceAddress.zipcode.trim())}`;

    this.userService.searchCustomerByAddressFromCustomers(params).subscribe(
      res => {
        this.addressPresentIn = 'customersDB';
        done(res.body.responseDesc);
        this.showLoader = false;
      },
      err => {
        let errorName = '';
        const apiError = err;
        errorName = apiError.error.error.name;
        if (errorName === 'AddressNotFoundError' || errorName === 'InvalidAddressError' || errorName === 'NotFoundError') {
          const paramsAdd = `street=${encodeURIComponent(
            this.user.address[0].serviceAddress.streetName.trim() || '',
          )}&street_number=${encodeURIComponent(this.user.address[0].serviceAddress.streetNumber.trim())}&city=${encodeURIComponent(
            this.user.address[0].serviceAddress.city.trim(),
          )}&state=${encodeURIComponent(state.value)}&zip_code=${encodeURIComponent(
            this.user.address[0].serviceAddress.zipcode.trim(),
          )}&first_name=${encodeURIComponent(this.user.firstName.trim())}&last_name=${encodeURIComponent(this.user.lastName.trim())}
                &phone=${encodeURIComponent(this.user.phoneNumber.trim())}`;
          this.userService.searchCustomerByAddress(paramsAdd).subscribe(
            res => {
              this.addressPresentIn = 'customercrmsDB';
              done(res.body);
              this.showLoader = false;
            },
            error => {
              const GAErrorId = 'checkout_error_checkExistingUser';
              let errorMassgae = '';
              let errorData = '';
              const apiErr = error;
              errorData = apiErr.error.error.name;
              if (!['null', 'undefined', undefined, null, ''].includes(errorData) && errorData === 'MultipleCustomersFoundError') {
                errorMassgae = `We're sorry, but we are having difficulty creating your account as our records indicate there are multiple
                           accounts in your name. Please contact Customer Service at ${this.customer_service_number} to complete your registration.`;
                this.showAlertModal({
                  title: "We're sorry, we are having difficulty verifying your account information.",
                  message: errorMassgae,
                  alertIcon: 'fa fa-exclamation-circle red',
                  cancelText: ' ',
                  confirmText: 'Ok',
                  confirmBtnId: GAErrorId || 'close',
                });
              } else {
                done([]);
              }
            },
          );
        } else {
          done([]);
        }
      },
    );
  }

  goToStep(form: any, $event) {
    this.userName = `${form.value.firstName} ${form.value.lastName}`;
    this.isClicked = true;
    if (form.invalid) {
      if (form.controls.firstName && (form.controls.firstName.valid === false || form.controls.lastName.valid === false)) {
        $('html, body').animate(
          {
            scrollTop: $('#name').offset().top - 60,
          },
          1000,
        );
      } else if (
        form.controls.email &&
        (form.controls.email.valid === false ||
          form.controls.phoneNumber.valid === false ||
          (form.controls.businessPhone && form.controls.businessPhone.valid === false) ||
          (form.controls.phoneNumberExisting && form.controls.phoneNumberExisting.valid === false) ||
          this.isExistingUser ||
          this.isDisposableEmail)
      ) {
        $('html, body').animate(
          {
            scrollTop: $('#email').offset().top - 50,
          },
          1000,
        );
      } else if (
        form.controls.city &&
        (form.controls.city.valid === false ||
          form.controls.state.valid === false ||
          form.controls.streetNumber.valid === false ||
          form.controls.streetName.valid === false)
      ) {
        $('html, body').animate(
          {
            scrollTop: $('#service-address').offset().top,
          },
          1000,
        );
      }
      return;
    }
    this.states = ['null', 'undefined', undefined, null, ''].includes(this.states)
      ? this.sessionStorageService.getItem('statesList')
        ? JSON.parse(this.sessionStorageService.getItem('statesList'))
        : this.states
      : this.states;
    if (
      form.controls.state.value ===
      find(this.states, {
        key: this.stateCode,
      }).value
    ) {
      this.showLoader = true;
      if (form.valid && this.zipcodeValid && !this.isDisposableEmail) {
        const searchAddress = this.user.address[0].serviceAddress;
        this.getSmartAddress(
          searchAddress.streetNumber,
          searchAddress.streetName || '',
          searchAddress.state,
          searchAddress.city,
          searchAddress.zipcode,
          upDateAddress => {
            if (upDateAddress) {
              if (this.globalVariables.OOFSelected && this.globalVariables.OOFSelected.isOOFLocationRegister === 'true') {
                /* Add code if need to set other field for ooflocation */
              } else {
                this.zipcodeService.getZipcodeId(upDateAddress.zipcode).subscribe(
                  res => {
                    // this.onZipcodeUpdated(res);
                    res = res.body;
                    this.user.address[0].serviceAddress.zipcodeId = res.zipCodeId;
                    this.user.address[0].serviceAddress.locationCodeId = res.locationCodeId;
                  },
                  () => {},
                );
              }
              if (this.user.address[0].serviceAddress.zipcode !== upDateAddress.zipcode) {
                this.oldZipcode = JSON.parse(JSON.stringify(this.user.address[0].serviceAddress.zipcode));
              }
              this.user.address[0].serviceAddress.streetNumber = upDateAddress.streetNumber;
              this.user.address[0].serviceAddress.streetName = upDateAddress.streetName || '';
              this.user.address[0].serviceAddress.city = upDateAddress.city;
              this.user.address[0].serviceAddress.state = upDateAddress.state;
              this.user.address[0].serviceAddress.zipcode = upDateAddress.zipcode;
              this.addressLookup.zipcode = this.user.address[0].serviceAddress.zipcode;
            }
            this.checkExistingUser(form, response => {
              if (!!response && response.length === 0) {
                this.isClicked = false;
                if (!this.isExistingUser) {
                  this.registerFormSubmit.next({
                    data: this.getProperCustomerData(),
                    type: this.addressPresentIn,
                    case: 'NonMatches',
                    $event,
                    emailExistedData: this.emailExistedData,
                  });
                } else {
                  if (this.addressPresentIn === 'customercrmsDB') {
                    this.existingUser = this.formatSyncData(this.existingCustomerData);
                  }
                  this.registerFormSubmit.next({
                    data: this.getProperCustomerData(),
                    type: this.addressPresentIn,
                    case: 'EmailMatched',
                    $event,
                    emailExistedData: this.emailExistedData,
                  });
                }
              } else if (response.length > 0) {
                this.isClicked = false;
                const currentCustomer = this.checkCustomerExists(response, form.controls.lastName.value, form.controls.email.value);
                if (currentCustomer) {
                  this.existingCustomerData = currentCustomer;
                  const existingCustomerDataCopy = {
                    ...this.existingCustomerData,
                  };
                  this.setExistingCustomerData(existingCustomerDataCopy);
                  if (!this.isExistingUser) {
                    if (this.addressPresentIn === 'customercrmsDB') {
                      this.existingUser = this.formatSyncData(this.existingCustomerData);
                    }
                    this.registerFormSubmit.next({
                      data: this.getProperCustomerData(),
                      type: this.addressPresentIn,
                      case: 'AddressMatched',
                      $event,
                      emailExistedData: this.emailExistedData,
                    });
                  } else {
                    if (this.addressPresentIn === 'customercrmsDB') {
                      this.existingUser = this.formatSyncData(this.existingCustomerData);
                    }
                    this.registerFormSubmit.next({
                      data: this.getProperCustomerData(),
                      type: this.addressPresentIn,
                      case: 'AllMatched',
                      $event,
                      emailExistedData: this.emailExistedData,
                    });
                  }
                } else {
                  if (this.addressPresentIn === 'customercrmsDB') {
                    this.lastNameMismatchFound = response[0].Customer_Number;
                  } else if (this.addressPresentIn === 'customersDB') {
                    this.lastNameMismatchFound = response[0].address.customerNo;
                  }
                  if (this.isExistingUser) {
                    if (this.addressPresentIn === 'customercrmsDB') {
                      this.existingUser = this.formatSyncData(this.existingCustomerData);
                    }
                    this.registerFormSubmit.next({
                      data: this.getProperCustomerData(),
                      type: this.addressPresentIn,
                      case: 'EmailMatched',
                      $event,
                      emailExistedData: this.emailExistedData,
                    });
                  } else {
                    this.existingCustomerData = response[0];
                    this.registerFormSubmit.next({
                      data: this.getProperCustomerData(),
                      type: this.addressPresentIn,
                      case: 'NonMatches',
                      $event,
                      emailExistedData: this.emailExistedData,
                    });
                  }
                }
              }
            });
          },
        );
      } else {
        this.states = ['null', 'undefined', undefined, null, ''].includes(this.states)
          ? this.sessionStorageService.getItem('statesList')
            ? JSON.parse(this.sessionStorageService.getItem('statesList'))
            : this.states
          : this.states;
        this.showAlertModal({
          title: 'Error',
          message: `Zipcode does not exist in selected state, Please select ${
            find(this.states, {
              key: this.stateCode,
            }).value
          } to proceed.`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: 'Cancel',
          confirmText: 'Ok',
        });
      }
    }
  }

  getProperCustomerData() {
    return this.addressPresentIn === 'customercrmsDB' ? this.existingUser : this.existingCustomerData;
  }

  checkCustomerExists(customerList, lastName, email) {
    let customerFound = null;
    let emailMatched = false;
    lastName = lastName.toLowerCase().trim();
    email = email.toLowerCase().trim();
    for (let i = 0; i < customerList.length; i++) {
      const currentCustomer = customerList[i];
      if (
        currentCustomer.Last_Name &&
        currentCustomer.Last_Name.toLowerCase().trim() === lastName &&
        currentCustomer.email &&
        currentCustomer.email.toLowerCase().trim() === email
      ) {
        customerFound = currentCustomer;
        break;
      } else if (currentCustomer.email && currentCustomer.email.toLowerCase().trim() === email) {
        customerFound = currentCustomer;
        emailMatched = true;
      } else if (
        this.addressPresentIn === 'customersDB' &&
        currentCustomer.lastName &&
        currentCustomer.lastName.toLowerCase().trim() === lastName &&
        !emailMatched
      ) {
        customerFound = currentCustomer;
      } else if (
        this.addressPresentIn === 'customercrmsDB' &&
        currentCustomer.Last_Name &&
        currentCustomer.Last_Name.toLowerCase().trim() === lastName &&
        !emailMatched
      ) {
        customerFound = currentCustomer;
      }
    }
    return customerFound;
  }

  setExistingCustomerData(existingCustomer: any) {
    return new Promise(resolve => {
      if (this.addressPresentIn === 'customercrmsDB') {
        this.existingUser = this.formatSyncData(existingCustomer);
      } else {
        this.existingUser = existingCustomer;
        if (!Array.isArray(this.existingUser.address)) {
          this.existingUser.address = [JSON.parse(JSON.stringify(existingCustomer.address))];
        }
      }
      //this.existingUser.custAccountId = this.existingUser.custAccountId ? this.existingUser.custAccountId : this.customerDbData.custAccountId[0];
      this.existingUser.oracleCustId = this.existingUser.oracleCustId ? this.existingUser.oracleCustId : this.customerDbData?.address[0]?.oracleCustId;
      const zipcodes = [];

      if (this.existingUser.address[0].serviceAddress.zipcode === this.existingUser.address[0].billingAddress.zipcode) {
        zipcodes.push(this.zipcodeService.getZipcodeId(this.existingUser.address[0].serviceAddress.zipcode));
      } else {
        zipcodes.push(this.zipcodeService.getZipcodeId(this.existingUser.address[0].serviceAddress.zipcode));
        zipcodes.push(this.zipcodeService.getZipcodeId(this.existingUser.address[0].billingAddress.zipcode));
      }
      this.getZipcodeData(zipcodes, zipcodedata => {
        // zipcodedata = zipcodedata['body'];
        if (!!this.existingUser.address[0].waterBillOnOff && !!this.existingUser.address[0].noWaterBillConversion) {
          const oofLocationCode = `${this.existingUser.address[0].serviceAddress.state}150`;
          this.productService.getProviderContent(zipcodedata[0].locationCodeId).subscribe(dataForLocation => {
            dataForLocation = dataForLocation.body;
            if (oofLocationCode !== dataForLocation.locationCode) {
              this.productService.getLocationCode(oofLocationCode).subscribe(dataForOofLocationCode => {
                dataForOofLocationCode = dataForOofLocationCode.body;
                this.locationCodeData = dataForOofLocationCode[0];
                zipcodedata[0].locationCodeId = dataForOofLocationCode[0].locationCodeId;
                resolve(zipcodedata);
              });
            } else {
              this.productService.getProviderContent(zipcodedata[0].locationCodeId).subscribe(dataForLoc => {
                this.locationCodeData = dataForLoc.body;
                resolve(dataForLoc);
              });
            }
          });
        } else {
          this.productService.getProviderContent(zipcodedata[0].locationCodeId).subscribe(dataForLocation => {
            this.locationCodeData = dataForLocation.body;
            resolve(dataForLocation);
          });
        }

        this.existingUser.address[0].serviceAddress.zipcodeId = zipcodedata[0].zipCodeId;
        this.existingUser.address[0].serviceAddress.locationCodeId = zipcodedata[0].locationCodeId;
        if (zipcodedata.length === 1) {
          this.existingUser.address[0].billingAddress.zipcodeId = zipcodedata[0].zipCodeId;
          this.existingUser.address[0].billingAddress.locationCodeId = zipcodedata[0].locationCodeId;
        } else if (zipcodedata.length === 2) {
          this.existingUser.address[0].billingAddress.zipcodeId = zipcodedata[1].zipCodeId;
          this.existingUser.address[0].billingAddress.locationCodeId = zipcodedata[1].locationCodeId;
        }
        $('html, body').animate(
          {
            scrollTop: 0,
          },
          1000,
        );
      });
      /* HWR-2485 End */
    });
  }

  getZipcodeData(zipcodes, done: any) {
    forkJoin(zipcodes).subscribe(
      zipcodesData => {
        const zipcodeData: any = [];
        (zipcodesData as any).forEach(ele => {
          zipcodeData.push(ele.body);
        });
        done(zipcodeData);
      },
      () => {
        const errorTitle = "We're sorry, there was a problem attempting to create your account.";
        const strError = `Please contact Customer Service at <span class="app-txt">
            ${this.customer_service_number}</span>`;
        this.showLoader = false;
        this.showErrorModal(errorTitle, strError, ErrorModalComponent);
      },
    );
  }

  showErrorModal(title: String, message: String, modalComponent: any, GAErrorId?: string, isZipChanged?: boolean) {
    this.simpleModalService
      .addModal(
        modalComponent,
        {
          title,
          message,
          confirmBtnId: GAErrorId || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed === undefined) {
          /** Save Checkout User Form Data */
          this.user.address[0].serviceAddress.zipcode =
            this.addressLookup !== '' ? this.addressLookup.zipcode : JSON.parse(this.sessionStorageService.getItem('selectedAddress')).zipcode;
          this.sessionStorageService.setItem('user_data', JSON.stringify(this.user));
          if (isZipChanged) {
            const { locationCodeId } = this.user.address[0].serviceAddress;
            this.sessionStorageService.setItem('location_code', locationCodeId);
            this.addressLookup.zipcode = this.user.address[0].serviceAddress.zipcode;
          }
        }
      });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  registerCustomer(payload) {
    this.showLoader = true;
    this.userService.registerCustomer(payload).subscribe(
      data => {
        this.globalVariables.OOFSelected = {};
        this.showLoader = false;
        if (data.status === 200) {
          this.customerDetails = data.body;
          this.globalVariables.passNewRegisterCustomerAccount = JSON.parse(JSON.stringify(payload));
          this.globalVariables.passNewRegisterCustomerAccount.resSource = 'register';
          this.customerGuid = data.body.data.customerGuid;
          $('html, body').animate(
            {
              scrollTop: 0,
            },
            1000,
          );
          this.fetchToken(payload.email);
        }
      },
      error => {
        const GAErrorId = 'checkout_error_registerCustomer';
        this.isClicked = false;
        this.showLoader = false;
        let errorTitle = "We're sorry, there was a problem attempting to create your account.";
        let strError: String = 'Please call the number at the top of page to speak to a Customer Service Representative.';
        if (error.status === 409) {
          strError = 'User already exists';
          errorTitle = 'Error';
        }
        if (this.oldZipcode && this.oldZipcode !== payload.address[0].serviceAddress.zipcode) {
          this.globalVariables.cartCheckout.items[0].zipcode = payload.address[0].serviceAddress.zipcode;
          this.zipcodeComponent.zipcodeChangedFromSuggestion(payload.address[0].serviceAddress.zipcode);
        }
        this.showErrorModal(errorTitle, strError, ErrorModalComponent, GAErrorId);
      },
    );
  }

  async checkSwitch(form: NgForm, regType, existingCustomerData, pageViewName?) {
    switch (regType) {
      case 'NonMatches': {
        // in case of non match , show pop up
        const alertData = {
          title: "We're sorry, but we could not locate the property address you have entered.",
          message: `Please contact Customer Service at <span class="app-txt">
              ${this.customer_service_number}</span> to verfiy your coverage.`,
          alertIcon: 'fa fa-info-circle red',
          cancelText: ' ',
          confirmText: 'Close',
          reloadPage: true,
        };
        this.showAlertModal(alertData);
        break;
      }
      case 'AddressMatched': {
        if (this.addressPresentIn === 'customercrmsDB') {
          this.showLoader = true;
          if (this.existingUser.address[0].customerNo) {
            if (pageViewName !== 'enrollmentCard') {
              this.existingUser.email = this.isExistingCustReg ? form.value.loginId : form.value.email;
            }
            this.sanitize(this.existingUser);
            this.sanitize(this.user);
            if (this.referer) {
              this.existingUser.referer = this.referer;
            }
            this.existingUser.cellPhone1SmsOptInDate = new Date().toJSON().slice(0, 10);
          }
          const payload: any = this.existingUser.address[0] && this.existingUser.address[0].customerNo ? this.existingUser : this.user;
          delete payload.accountnumber;
          payload.isFromCheckout = true;
          if (form.value.authorizedTosenDMessage) {
            payload.isAuthorizedToSendMessage = 1;
          } else {
            payload.isAuthorizedToSendMessage = 0;
          }
          // if (payload.password === form.value.confirmPassword) {
          if (!payload.address[0].serviceAddress.streetName) {
            payload.address[0].serviceAddress.streetName = '';
          }
          if (this.referer) {
            payload.retainUrl = this.referer;
          }
          payload.utmParams = localStorage.getItem('utmParams') || '';
          payload.address[0].providerAvailable = this.providerAvailable;
          if (this.providerAvailable) {
            payload.address[0].providerSelected = this.providerSelected;
          }
          await new Promise(resolve => {
            setTimeout(resolve, 2000);
          });
          // update the locationcodeId if the existing customer does not hace the locationCodeId
          if(!this.existingCustomerData.LocationCodeId){
            this.showAlertModal({
              title: "We're sorry, there was a problem attempting to create your account due to missing location.",
              message: "Please call the number at the top of page to speak to a Customer Service Representative.",
              alertIcon: 'fa fa-exclamation-circle red',
              cancelText: ' ',
              confirmText: 'Ok',
              reloadPage: true,
            });
          }
          else if (this.existingUserLocationCodeId) {
            this.locationService.getLocationsContent(this.existingUserLocationCodeId).subscribe(
              dataReceivedRes => {
                const dataReceived = dataReceivedRes.body;
                if (dataReceived && dataReceived.locationCode.includes('150')) {
                  payload.address[0].providerAvailable = true;
                  payload.address[0].providerSelected = true;
                }
                payload.email = this.user.email ? this.user.email : payload.email;
                this.registerCustomer(payload);
              },
              () => {
                const GAErrorId = 'checkout_error_fetching_LocationContent';
                const title = 'We are currently facing technical difficulties.';
                const message = '<br><p style="margin-top:-20px;margin-bottom:0;">Please try again after sometime. Sorry for any inconvenience.';
                this.showLoader = false;
                this.showErrorModal(title, message, ErrorModalComponent, GAErrorId);
              },
            );
          } else {
            payload.email = this.user.email ? this.user.email : payload.email;
            this.registerCustomer(payload);
          }
          // }
        } else if (this.addressPresentIn === 'customersDB') {
          this.customerGuid = this.existingUser.address[0].customerGuid;
          this.customerDetails = {
            data: {
              customerGuid: this.existingUser.address[0].customerGuid,
              customerNo: this.existingUser.address[0].customerNo,
              customerId: this.existingUser.customerId,
            },
          };
          this.globalVariables.passNewRegisterCustomerAccount = JSON.parse(JSON.stringify(this.existingUser.address[0]));
          this.globalVariables.passNewRegisterCustomerAccount.resSource = 'customersDB';
          this.globalVariables.passNewRegisterCustomerAccount.email = this.user.email;
          if (this.existingUser.address && 'isFromCheckout' in this.existingUser.address[0]) {
            this.globalVariables.passNewRegisterCustomerAccount.isFromCheckout = this.existingUser.address[0].isFromCheckout;
          }
          this.fetchToken(this.existingUser.email);
        }
        break;
      }
      case 'EmailMatched': {
        // in case of email match , show pop up
        const alertData = {
          title: "We're sorry, but we could not locate the property address you have entered.",
          message: `Please contact Customer Service at <span class="app-txt">
              ${this.customer_service_number}</span> to verfiy your coverage.`,
          alertIcon: 'fa fa-info-circle red',
          cancelText: ' ',
          confirmText: 'Close',
          reloadPage: true,
        };
        this.showAlertModal(alertData);
        break;
      }
      case 'AllMatched': {
        if (this.addressPresentIn === 'customercrmsDB') {
          const customerid: any = this.emailExistedData ? this.emailExistedData.user._id : '';
          if (this.emailExistedData && this.emailExistedData.user.lastName.trim() === this.user.lastName.trim()) {
            if (this.existingUserLocationCodeId) {
              this.locationService.getLocationsContent(this.existingUserLocationCodeId).subscribe(
                async dataReceivedRes => {
                  const dataReceived = dataReceivedRes.body;
                  const payloadAllMatched: any = {
                    serviceAddress: this.existingUser.address[0].serviceAddress,
                    billingAddress: this.existingUser.address[0].serviceAddress,
                    isBillingAddressSameAsServiceAddress: true,
                    ownOrRentedValidated: this.existingUser.address[0].ownOrRentedValidated,
                    noWaterBillConversion: this.existingUser.address[0].noWaterBillConversion,
                    waterBillOnOff: this.existingUser.address[0].waterBillOnOff,
                    firstName: this.user.firstName || '',
                    lastName: this.user.lastName,
                  };
                  payloadAllMatched.isFromCheckout = true;
                  payloadAllMatched.providerAvailable = this.providerAvailable;
                  payloadAllMatched.customerNo = this.existingUser.address[0].customerNo;
                  payloadAllMatched.customerGuid = this.existingUser.address[0].customerGuid;
                  if (this.providerAvailable) {
                    payloadAllMatched.providerSelected = this.providerSelected;
                  }
                  if (dataReceived && dataReceived.locationCode.includes('150')) {
                    payloadAllMatched.providerAvailable = true;
                    payloadAllMatched.providerSelected = true;
                  }
                  await new Promise(resolve => {
                    setTimeout(resolve, 2000);
                  });
                  // payloadAllMatched.address[0].serviceAddress.locationCodeId = this.existingCustomerData.LocationCodeId;
                  // payloadAllMatched.address[0].billingAddress.locationCodeId = this.existingCustomerData.LocationCodeId;
                  this.userService.addCustomerAddress(customerid, payloadAllMatched).subscribe(
                    res => {
                      if (res.status === 200) {
                        this.customerGuid = res.body.data.customerGuid;
                        this.customerDetails = res.body;
                        this.globalVariables.passNewRegisterCustomerAccount = JSON.parse(JSON.stringify(payloadAllMatched));
                        this.globalVariables.passNewRegisterCustomerAccount.resSource = 'address';
                        this.globalVariables.passNewRegisterCustomerAccount.email = this.user.email;
                        this.fetchToken(this.user.email);
                      }
                      this.showLoader = false;
                    },
                    () => {
                      const GAErrorId = 'checkout_error_addCustomerAddress';
                      const errorTitle = "We're sorry, there was a problem attempting to create your account.";
                      const strError: String = 'Please call the number at the top of page to speak to a Customer Service Representative.';
                      this.showLoader = false;
                      if (this.oldZipcode && this.oldZipcode !== this.existingUser.address[0].serviceAddress.zipcode) {
                        this.globalVariables.cartCheckout.items[0].zipcode = this.existingUser.address[0].serviceAddress.zipcode;
                        this.zipcodeComponent.zipcodeChangedFromSuggestion(this.existingUser.address[0].serviceAddress.zipcode);
                      }
                      $('html, body').animate(
                        {
                          scrollTop: 0,
                        },
                        'slow',
                      );
                      this.showErrorModal(errorTitle, strError, ErrorModalComponent, GAErrorId);
                    },
                  );
                  //   }
                },
                () => {
                  const GAErrorId = 'checkout_error_fetching_LocationContent';
                  const title = 'We are currently facing technical difficulties.';
                  const message = '<br><p style="margin-top:-20px;margin-bottom:0;">Please try again after sometime. Sorry for any inconvenience.';
                  this.showLoader = false;
                  this.showErrorModal(title, message, ErrorModalComponent, GAErrorId);
                },
              );
            } else {
              const payloadAllMatched: any = {
                serviceAddress: this.existingUser.address[0].serviceAddress,
                billingAddress: this.existingUser.address[0].serviceAddress,
                isBillingAddressSameAsServiceAddress: true,
                ownOrRentedValidated: this.existingUser.address[0].ownOrRentedValidated,
                noWaterBillConversion: this.existingUser.address[0].noWaterBillConversion,
                waterBillOnOff: this.existingUser.address[0].waterBillOnOff,
                firstName: this.existingUser.firstName || '',
                lastName: this.existingUser.lastName,
              };
              payloadAllMatched.isFromCheckout = true;
              payloadAllMatched.providerAvailable = this.providerAvailable;
              payloadAllMatched.customerNo = this.existingUser.address[0].customerNo;
              payloadAllMatched.customerGuid = this.existingUser.address[0].customerGuid;
              if (this.providerAvailable) {
                payloadAllMatched.providerSelected = this.providerSelected;
              }
              await new Promise(resolve => {
                setTimeout(resolve, 2000);
              });
              this.userService.addCustomerAddress(customerid, payloadAllMatched).subscribe(
                res => {
                  if (res.status === 200) {
                    this.customerGuid = res.body.data.customerGuid;
                    this.customerDetails = res.body;
                    this.globalVariables.passNewRegisterCustomerAccount = JSON.parse(JSON.stringify(payloadAllMatched));
                    this.globalVariables.passNewRegisterCustomerAccount.resSource = 'address';
                    this.globalVariables.passNewRegisterCustomerAccount.email = this.user.email;
                    this.fetchToken(this.user.email);
                  }
                  this.showLoader = false;
                },
                () => {
                  const GAErrorId = 'checkout_error_addCustomerAddress';
                  const errorTitle = "We're sorry, there was a problem attempting to create your account.";
                  const strError: String = 'Please call the number at the top of page to speak to a Customer Service Representative.';
                  this.showLoader = false;
                  if (this.oldZipcode && this.oldZipcode !== this.existingUser.address[0].serviceAddress.zipcode) {
                    this.globalVariables.cartCheckout.items[0].zipcode = this.existingUser.address[0].serviceAddress.zipcode;
                    this.zipcodeComponent.zipcodeChangedFromSuggestion(this.existingUser.address[0].serviceAddress.zipcode);
                  }
                  $('html, body').animate(
                    {
                      scrollTop: 0,
                    },
                    'slow',
                  );
                  this.showErrorModal(errorTitle, strError, ErrorModalComponent, GAErrorId);
                },
              );
              // }
            }
          } else {
            // show error modal
          }
        } else if (this.addressPresentIn === 'customersDB') {
          this.customerGuid = existingCustomerData.address.customerGuid;
          this.customerDetails = {
            data: {
              customerGuid: existingCustomerData.address.customerGuid,
              customerNo: existingCustomerData.address.customerNo,
              customerId: existingCustomerData.customerId,
            },
          };
          this.globalVariables.passNewRegisterCustomerAccount = JSON.parse(JSON.stringify(existingCustomerData.address));
          this.globalVariables.passNewRegisterCustomerAccount.resSource = 'customersDB';
          this.globalVariables.passNewRegisterCustomerAccount.email = this.user.email;
          if (existingCustomerData.address && 'isFromCheckout' in existingCustomerData.address) {
            this.globalVariables.passNewRegisterCustomerAccount.isFromCheckout = existingCustomerData.address.isFromCheckout;
          }
          this.fetchToken(this.existingUser.email ? this.existingUser.email : existingCustomerData.email);
        }
        break;
      }
    }
  }

  sanitize(obj: Object) {
    Object.keys(obj).forEach(prop => {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (typeof obj[prop] === 'object') {
          this.sanitize(obj[prop]);
        } else if (!obj[prop] && obj[prop] !== 0) {
          delete obj[prop];
        }
      }
    });
  }

  fetchToken(email: any) {
    this.showLoader = true;
    this.userService.getGuestToken(email).subscribe(
      async res => {
        if (res.status === 200) {
          this.guestToken = res.body.authHeader;
          this.sessionStorageService.setItem('guestToken', this.guestToken);
          this.userDataInput = res.body;
          this.showEnrollmentCards.next({
            data: res.body,
            customerDetails: this.customerDetails,
            locationCodeData: this.locationCodeData,
          });
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  formatSyncData(existingCustomer) {
    const existingUser: any = {};
    // hot fix optincellphone date
    //existingUser.custAccountId = existingCustomer.custAccountId;
    existingUser.oracleCustId = existingCustomer.OracleCustId;
    existingUser.cellPhone1SmsOptInDate = new Date().toJSON().slice(0, 10);
    existingUser.accountnumber = existingCustomer.Customer_Number;

    if (existingCustomer.telephone) {
      existingUser.phoneNumber = existingCustomer.telephone.phoneNumber1;
      existingUser.phoneNumber2 = existingCustomer.telephone.phoneNumber2 || '';
    }

    if (existingCustomer.Email) {
      existingUser.email = existingCustomer.Email;
    } else if (existingCustomer.Email2) {
      existingUser.email = existingCustomer.Email2;
    } else if (existingCustomer.Email3) {
      existingUser.email = existingCustomer.Email3;
    }

    existingUser.firstName = existingCustomer.First_Name || '';
    existingUser.lastName = existingCustomer.Last_Name || '';
    existingUser.middleName = existingCustomer.Middle_Name || '';

    const contactType = existingCustomer.Contact_Type ? existingCustomer.Contact_Type : 1;
    let setContactType: any = '';
    if (contactType === 3 || contactType === 7) {
      setContactType = 'COMMERCIAL';
    } else {
      setContactType = 'RESIDENTIAL';
    }
    this.states = ['null', 'undefined', undefined, null, ''].includes(this.states)
      ? this.sessionStorageService.getItem('statesList')
        ? JSON.parse(this.sessionStorageService.getItem('statesList'))
        : this.states
      : this.states;
    const serviceStateVal = find(this.states, {
      key: existingCustomer.Service_address.Service_State.toString(),
    });
    const billingStateVal = find(this.states, {
      key: existingCustomer.Billing_Address.Billing_State.toString(),
    });
    this.existingUserLocationCodeId = existingCustomer.LocationCodeId;
    existingUser.address = [
      {
        customerNo: existingCustomer.Customer_Number,
        customerGuid: existingCustomer.Customer,
        waterBillOnOff: existingCustomer.WaterBillOnOff || true,

        ownOrRentedValidated: existingCustomer.OwnOrRentValidated ? existingCustomer.OwnOrRentValidated.toString() : 'false',
        noWaterBillConversion: existingCustomer.NoWaterBillConversion ? existingCustomer.NoWaterBillConversion.toString() : 'false',
        // [HWR-2723 Feilds add for insert FirstName/LastName for existing customer registration]
        firstName: existingCustomer.First_Name || '',
        lastName: existingCustomer.Last_Name,

        serviceAddress: {
          streetName: existingCustomer.Service_address.Service_street2 || '',
          streetNumber: existingCustomer.Service_address.Service_Street_Number.toString(),
          city: existingCustomer.Service_address.Service_City,
          state: serviceStateVal.value,
          zipcode: existingCustomer.Service_address.Service_zipcode,
          contactType: setContactType,
        },

        billingAddress: {
          streetName: existingCustomer.Billing_Address.Billing_street2 || '',
          streetNumber: existingCustomer.Billing_Address.Billing_Street_Number.toString(),
          city: existingCustomer.Billing_Address.Billing_City || '',
          state: (billingStateVal ? billingStateVal.value : '') || '',
          zipcode: existingCustomer.Billing_Address.Billing_zipcode || '',
          contactType: setContactType || '',
        },
      },
    ];

    return existingUser;
  }
}
