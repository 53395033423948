<div *ngIf="showNeighborhoodServices" class="other-services-link" [ngClass]="{ 'hide-link': isVisible }">
  <div (click)="showPages(true)"><span class="right-cat-icon fa fa-chevron-right"></span> Other Services you may be interested in</div>
</div>

<!-- Neighbourhood services section -->
<section
  *ngIf="showNeighborhoodServices"
  class="content-section content-block services-section neighbourhood-service PB-0"
  [ngClass]="{ active: isVisible }"
  id="neighbourhood-service-id"
>
  <div>
    <article class="page-wrap row">
      <div class="col-12">
        <h2 class="section-head-title font-unbounce-title full-width-mob">
          <span>Related Products</span>
        </h2>
      </div>
    </article>
    <article class="page-wrap row set-border paddTM20 paddB50">
      <div class="desktop-only-view w-100">
        <ngu-carousel [inputs]="carouselTile" [dataSource]="products" class="category-tile col-12">
          <ngu-tile *nguCarouselDef="let product of products; let i = index" class="item">
            <hos-product-card-details-page
              [index]="i"
              [shareIconIndexArray]="shareIconIndexArray"
              [locData]="locData"
              [product]="product"
              [zipcode]="zipcode"
              [locationId]="locationId"
              [showOldPrice]="setOldPriceFlag"
              [products]="allProductDetails"
            ></hos-product-card-details-page>
          </ngu-tile>
          <span NguCarouselPrev class="cat-slide-icon left-cat-icon fa fa-chevron-left" [ngStyle]="{ display: 'none' }"></span>
          <span NguCarouselNext class="cat-slide-icon right-cat-icon fa fa-chevron-right" [ngStyle]="{ display: 'none' }"></span>
        </ngu-carousel>
      </div>
      <div class="text-center col-12 col-sm-12 col-md-12" *ngIf="isAsteriskPresent">
        * Program Fees listed only apply to the first year of the program. Program Fees are subject to change.
      </div>
      <div class="text-center col-12 col-sm-12 col-md-12" *ngIf="providerState">
        † LVVWD's First Year Reimbursement Promotion: AWR is not responsible for the Promotion or reimbursement payments. The Promotion is subject to change or termination at LVVWD's sole discretion. Only eligible LVVWD account holders who own the enrolled property can receive the Promotion. Customers who receive the Promotion will be notified by LVVWD; customers who are not eligible for the Promotion will not be notified. Reimbursement will be issued in the form of a check from LVVWD to the address on the customer's LVVWD account. For monthly-paying customers that receive the Promotion, the reimbursement will be issued within 90 days after twelve months of active enrollment. Customers who pay annually at the start of their term will be reimbursed within six months of enrollment.
      </div>
      <div class="row page-wrap not-desktop-view">
        <hos-product-card-details-page
          *ngFor="let product of products; let i = index"
          class="col-6 mb-5"
          [index]="i"
          [shareIconIndexArray]="shareIconIndexArray"
          [locData]="locData"
          [product]="product"
          [zipcode]="zipcode"
          [locationId]="locationId"
          [showOldPrice]="setOldPriceFlag"
          [products]="allProductDetails"
        ></hos-product-card-details-page>
      </div>
      <div class="col-12 col-sm-12 col-md-12 mb-5 text-center" *ngIf="otherProducts?.length === 0">No other services available.</div>
    </article>
  </div>
</section>

<div id="backdrop-id" (click)="showPages()"></div>
