<!-- <hos-breadcrumb [config]="breadcrumsConfig"></hos-breadcrumb> -->
<!-- <section class="content-section shaded-grey location-section">
    <article class="page-wrap selected-loc-area">
        <div class="grad location-box text-box" [hidden]="!selectedAddress">
            <span class="current-location-label">Current Location</span>
            <br />
            <span class="current-location">
                {{selectedAddress?.zipcode}}
            </span>
            <br />
            <hr class="hr-line">
            <a (click)="changeAddress()" class="change-link">
                <i class="fa fa-pencil" aria-hidden="true"></i>
                Change
            </a>
        </div>
        <div class="grad location-box input-box" [hidden]="selectedAddress && selectedAddress.zipcode">
            <h5 class="label-text">Enter your Zip Code or Address to see availibility</h5>
            <hos-google-places (addressHandler)='addressHandler($event)'></hos-google-places>
        </div>

    </article>
</section> -->

<section *ngIf="!isPageNotFoundComp" [ngClass]="{ 'fixed-nav-product': fixedHeader }" class="product-list-area content-section shaded-white mt-4">
  <!-- <div class="page-wrap row pt-2 mt-1 mb-2 partner-section-top" *ngIf="!!sessionStorageService.getItem('websitePartnerName')">
        <div class="col-6">
            <p class=" partnership-with mb-0">Partnership with</p>
            <p>
                <span>{{sessionStorageService.getItem('websitePartnerName')}}</span>
            </p>
        </div>
        <div class="col-6 text-right pt-3 partnerlogo" *ngIf="!!locData">
            <img [src]="providerBaseUrl+locData.providerIcon">
        </div>
    </div> -->
  <div class="row product-list-title">
    <div class="col-12 col-md-12 col-lg-12">
      <h2 class="title-label">We've got your back</h2>
      <h3 class="title-text">The following plans are available in your area</h3>
    </div>
  </div>
  <article class="page-wrap product-list row">
    <div class="col-12 col-sm-6 col-md-3 product-item" *ngFor="let product of products; let ind = index">
      <hos-product-card-listing-page
        [index]="ind"
        [product]="product"
        [zipcode]="zipcode"
        [locationId]="locationCode"
        [locData]="locData"
        [refCheck]="listingPage"
        [locationCodeData]="locationCodeData"
        [allStates]="allStates"
        (loader)="loaderValue($event)"
        [shareIconIndexArray]="shareIconIndexArray"
        [showOldPrice]="setOldPriceFlag"
        [products]="products"
      ></hos-product-card-listing-page>
    </div>
    <!-- <div class="col-12 col-sm-12 col-md-3 assistance-box product-item" *ngIf="products.length > 0">
            <div class="card text-center">
                <div class="card-body">
                    <p>Act now to protect your home with a Protection Program offered by American Water Resources. Protect Yourself
                        Before a Home Emergency Strikes!</p>
                    <p>For immediate assistance call
                        <br>
                        <span class="contact-text">1-877-513-8520</span>
                    </p>
                </div>
            </div>
        </div> -->
    <!-- <div class="col-12 col-sm-12 col-md-3 mb-5 assistance-box partner-content-right" *ngIf="products.length > 0 && !!locData?.providerIcon">
            <div class="card text-center">
                <div class="card-body">
                    <p>{{locData.providerDesc}}</p>
                    <p>In partnership with
                        <br>
                        <img [src]="providerBaseUrl+locData.providerIcon" alt="Partner's Logo" class="card-img-top">
                    </p>
                </div>
            </div>
        </div> -->
    <div class="text-center col-12 col-sm-12 col-md-12" *ngIf="!showLoader && products.length === 0">
      There are currently no protection programs available in your area. Please continue to check back for new programs.
    </div>
    <div class="text-center col-12 col-sm-12 col-md-12" *ngIf="isAsteriskPresent">
      * Program Fees listed only apply to the first year of the program. Program Fees are subject to change.
    </div>
    <div class="text-center col-12 col-sm-12 col-md-12" *ngIf="providerState">
      † LVVWD's First Year Reimbursement Promotion: AWR is not responsible for the Promotion or reimbursement payments. The Promotion is subject to change or termination at LVVWD's sole discretion. Only eligible LVVWD account holders who own the enrolled property can receive the Promotion. Customers who receive the Promotion will be notified by LVVWD; customers who are not eligible for the Promotion will not be notified. Reimbursement will be issued in the form of a check from LVVWD to the address on the customer's LVVWD account. For monthly-paying customers that receive the Promotion, the reimbursement will be issued within 90 days after twelve months of active enrollment. Customers who pay annually at the start of their term will be reimbursed within six months of enrollment.
    </div>
  </article>
  <div class="row product-list-header">
    <div class="col-12 col-md-12 col-lg-12 zipcode-box">
      <label class="desktop-only search-label">List of program services available for this area</label>
      <label class="mobile-only dark-text">Program Services in</label>
      <div class="grad location-box text-box" [hidden]="!selectedAddress || !selectedAddress.zipcode">
        <span class="current-location skiptranslate"> <i class="fa fa-map-marker" aria-hidden="true"></i> {{ selectedAddress?.zipcode }} </span>
        <button class="btn btn-link non-href-links btn-sm primary-link change-link change-btn" (click)="changeAddress()">Change</button>
      </div>
      <div
        id="listingPage"
        *ngIf="isZipcodeChangeClicked"
        [hidden]="selectedAddress && selectedAddress.zipcode"
        [ngClass]="{ 'd-flex search-div': !selectedAddress || !selectedAddress.zipcode }"
      >
        <hos-google-places
          [btnText]="
            zipcodeLookupTexts && zipcodeLookupTexts.productListingPageButtonText !== '' ? zipcodeLookupTexts.productListingPageButtonText : 'SEARCH'
          "
          (addressHandler)="addressHandler($event)"
        ></hos-google-places>
      </div>
    </div>

    <div class="filter-products col-12 col-md-12 col-lg-6 d-none">
      <div class="row mx-0">
        <div class="form-group col-md-5 col-lg-6 pt-3 filter-type paddBM0">
          <hr class="small-device-mobile paddBM0" />
          <label for="selectType" class="font-style paddTM20">Type</label>
          <br />
          <div class="type-slider">
            <div class="slider-btn" *ngFor="let type of types" [ngClass]="{ 'slider-deselect': type.id !== filterArray[0].value }">
              <i *ngIf="type.id === filterArray[0].value" class="fa fa-lg pr-1 fa-check-circle fa-color"></i>
              <label class="slider-label" for="slider-input-{{ type.id }}" [ngClass]="{ 'slider-deselect-text': type.id !== filterArray[0].value }">
                <input
                  type="radio"
                  name="slider-input"
                  id="slider-input-{{ type.id }}"
                  value="slider-input-{{ type.id }}"
                  [(ngModel)]="type.id === filterArray[0].value"
                  (ngModelChange)="dropdownFilteredOpearation('type', type.id)"
                />{{ type.type }}</label
              >
            </div>
          </div>
        </div>

        <div class="form-group col-md-5 col-lg-6 pt-3">
          <label for="selectServices" class="font-style">Filter by Service Type</label>
          <br />
          <div class="btn-group custom-dropdown service-filter-dropdown" dropdown placement="top">
            <button dropdownToggle type="button" class="btn dropdown-toggle round-form">
              {{ mapCategoriesById[filterArray[1].value]?.categoryName || 'All Services' }}
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <li role="menuitem" (click)="dropdownFilteredOpearation('service', '')">
                <a class="dropdown-item"> <img class="service-icons" /> All Services</a>
              </li>
              <li role="menuitem" *ngFor="let category of categories" (click)="dropdownFilteredOpearation('service', category)">
                <a class="dropdown-item" [ngClass]="{ active: category._id === filterArray[1].value }">
                  <img [src]="getImage(productServiceUrl + category.icon)" class="service-icons" [alt]="category.categoryName" />
                  {{ category.categoryName }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="!isPageNotFoundComp" class="banner-box">
  <div class="banner curve-down" [ngStyle]="{ 'background-image': 'url(' + bannerImage + ')' }"></div>
  <div class="content-box container-fluid">
    <div class="page-wrap banner-content-box">
      <div class="row">
        <div class="col-12 p-0">
          <div class="text-center font24">
            Are you a landlord with multiple properties? We can help.
            <a href="mailto: AWR.MultipleEnrollments@amwater.com" class="contactUsLink">Contact Us.</a>
          </div>
        </div>
      </div>
      <div class="row centerAlign padd20">
        <div class="col-12">
          <div class="line displayInline leftLine"></div>
          <div class="how-it-works-txt text-center displayInline"><p class="howItWorks">How it works?</p></div>
          <div class="line displayInline rightLine"></div>
        </div>
      </div>
      <div class="row how-it-work-area" *ngIf="siteContent?.safetyStepsItems?.length">
        <div class="col-4 col-md-4 col-lg-4" *ngFor="let stepsItems of siteContent?.safetyStepsItems; last as isLast">
          <div class="row">
            <div class="col-12 col-md-4 col-lg-4">
              <div class="steps-image col-12 txtCenterMob widthM100">
                <img [src]="getImage(baseURL + stepsItems.icon.url)" [alt]="stepsItems?.title" />
              </div>
            </div>
            <div class="col-12 col-md-8 col-lg-8">
              <div class="stepsHeading">
                {{ stepsItems?.title }}
              </div>
              <div class="stepsDesc">
                {{ stepsItems?.desc }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-container *ngIf="isPageNotFoundComp">
  <hos-page-not-found404></hos-page-not-found404>
</ng-container>
<div [ngClass]="{ loading: showLoader }"></div>
<div class="back-to-top" *ngIf="fixedHeader" (click)="scrollToTop()"></div>
